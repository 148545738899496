import React from 'react'

const RecentProject = () => {
  return (
    <div className='h-screen w-screen  bg-richblack-100 flex flex-col  justify-center items-center'>

        <p className='font-[700]  text-4xl  '> This Page is Under Maintenace </p>
        <p  className='font-[500]  text-2xl  ' > Soon It Will be Available To Access </p>
        <p  className='font-[500]  text-2xl  '> Sorry for Inconvience</p>
      
    </div>
  )
}

export default RecentProject
